<style>
.app-body {
	overflow-x: unset;
}

.header-fixed .app-header {
	z-index: 1021;
}

.sidenav-sticky {
	top: 68px;
}

.sidenav-sticky .card-footer {
	padding: 2rem;
	background-color: #65a930;
	background: linear-gradient(90deg, #f4a261 0%, #e76f51 100%);
	color: white;
}

.sidenav-sticky .card-footer.success {
	background-color: #65a930;
	background: linear-gradient(90deg, #00af75 0%, #00a09c 100%);
}

.sidenav-sticky .card-footer .label {
	display: block;
	text-transform: uppercase;
	font-size: 0.7rem;
}

.sidenav-sticky .card-footer .score {
	font-size: 3rem;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<div>
			<b-row>
				<b-col cols="12" md="8">
					<!-- <h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
						{{ this.FormMSG(1, 'Green Film Prerequisite Form') }}
					</h1> -->

					<!--<p>{{ this.FormMSG(2,'Green Film Main Description') }}</p>-->
					<p>
						{{
							this.FormMSG(
								121,
								'Plan in advance the practical and streamlined application of the sustainability criteria to be adopted, using simple information and concrete, in order to minimize the impact on the environment, without slowing down the work of the team.'
							)
						}}
					</p>

					<p>
						{{
							this.FormMSG(
								122,
								'Plan the mobility of production in advance in order to optimize transport and thus reduce its environmental impact and economic.'
							)
						}}
					</p>
				</b-col>
			</b-row>

			<b-row>
				<b-col cols="12" md="8">
					<b-card v-if="GF">
						<b-row>
							<b-col id="form-step-1" lg="12">
								<div class="clearfix mb-4">
									<b-button variant="dark" size="sm" class="pull-right rounded-pill" v-b-modal.modal-gfhelp-1>
										<i class="icon-info"></i> {{ this.FormMSG(7, 'Help') }}</b-button
									>

									<h2 class="text-primary h4">{{ this.FormMSG(3, 'Mandatory Prerequisite') }}</h2>

									<b-modal header-class="header-class-modal-doc-package" id="modal-gfhelp-1" size="lg" hide-header hide-footer>
										<h3 class="mb-3">{{ this.FormMSG(3, 'Mandatory Prerequisite') }}</h3>
										<div v-html="this.FormMSG(103, 'Mandatory Prerequisite Help text')"></div>
										<div v-html="this.FormMSG(1031, 'Mandatory Prerequisite Help text PR1')"></div>
										<div v-html="this.FormMSG(1032, 'Mandatory Prerequisite Help text PR2')"></div>
										<div class="clearfix mt-3 pt-3 border-top">
											<b-button class="pull-right" variant="secondary" @click="$bvModal.hide('modal-gfhelp-1')">{{
												this.FormMSG(10, 'Thank you !')
											}}</b-button>
										</div>
									</b-modal>
								</div>

								<b-row class="border-top">
									<b-col cols="12" md="8" class="p-3">
										<strong class="text-secondary"> {{ this.FormMSG(4, 'PR1 - Sustainable development plan') }} </strong><br />
										<span class="text-info">{{ GF.numDocPr1 }} {{ this.FormMSG(70, 'document(s) uploaded') }}</span>
									</b-col>
									<b-col cols="12" md="4" class="p-3">
										<b-button variant="light" block v-b-modal.modal-gfdocs-1>{{ this.FormMSG(5, 'Manage documents') }}</b-button>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" md="8" class="p-3">
										<strong class="text-secondary"> {{ this.FormMSG(6, 'PR2 - Transport optimization plan') }} </strong><br />
										<span class="text-info">{{ GF.numDocPr2 }} {{ this.FormMSG(70, 'document(s) uploaded') }}</span>
									</b-col>
									<b-col cols="12" md="4" class="p-3">
										<b-button variant="light" block v-b-modal.modal-gfdocs-2>{{ this.FormMSG(5, 'Manage documents') }}</b-button>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</b-card>

					<b-modal
						header-class="header-class-modal-doc-package"
						id="modal-gfdocs-1"
						size="lg"
						v-bind:title="this.FormMSG(4, 'PR1 - Sustainable development plan')"
						hide-footer
						ref="modal1"
					>
						<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="PR1"></GreenFilmDocs>
						<div class="clearfix mt-3 pt-3 border-top">
							<!--                      <b-button class="pull-right" variant="secondary" @click="$bvModal.hide('modal-gfdocs-1')">{{ this.FormMSG(64,'Close window') }}</b-button>  -->
							<b-button class="pull-right" variant="secondary" @click="closeModal1">{{ this.FormMSG(64, 'Close window') }}</b-button>
						</div>
					</b-modal>

					<b-modal
						header-class="header-class-modal-doc-package"
						id="modal-gfdocs-2"
						size="lg"
						v-bind:title="this.FormMSG(6, 'PR2 - Transport optimization plan')"
						hide-footer
						ref="modal2"
					>
						<GreenFilmDocs :GFId="GF.id" GFType="greenfilm" GFSubType="PR2"></GreenFilmDocs>
						<div class="clearfix mt-3 pt-3 border-top">
							<b-button class="pull-right" variant="secondary" @click="closeModal2">{{ this.FormMSG(64, 'Close window') }}</b-button>
						</div>
					</b-modal>

					<b-card v-if="GF">
						<b-row>
							<b-col id="form-step-2" lg="12">
								<div class="clearfix mb-4">
									<b-button variant="dark" size="sm" class="pull-right rounded-pill" v-b-modal.modal-gfhelp-2>
										<i class="icon-info"></i> {{ this.FormMSG(7, 'Help') }}</b-button
									>

									<h2 class="text-primary h4">{{ this.FormMSG(11, 'Energy savings') }}</h2>

									<b-modal header-class="header-class-modal-doc-package" id="modal-gfhelp-2" size="lg" hide-header hide-footer>
										<h3 class="mb-3">{{ this.FormMSG(111, 'A. Energy savings') }}</h3>
										<div v-html="this.FormMSG(1110, 'Energy savings help text')"></div>
										<div v-html="this.FormMSG(1111, 'Energy savings Help text A1')"></div>
										<div v-html="this.FormMSG(1112, 'Energy savings Help text A2')"></div>
										<div v-html="this.FormMSG(1113, 'Energy savings Help text A3')"></div>
										<div class="clearfix mt-3 pt-3 border-top">
											<b-button class="pull-right" variant="secondary" @click="$bvModal.hide('modal-gfhelp-2')">{{
												this.FormMSG(10, 'Thank you !')
											}}</b-button>
										</div>
									</b-modal>
								</div>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group
											class="m-0"
											:label="FormMSG(12, 'A1 - Connection to local electricity network')"
											label-for="A1"
											:label-cols="11"
										>
											<b-form-checkbox size="md" id="A1" v-model="GF.a1ElectricityFromLocalNetwork" v-on:change="saveInformation()"
												><span v-if="GF.a1ElectricityFromLocalNetwork">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(62, 'A2 - Green energy')" label-for="A2" :label-cols="11">
											<b-form-checkbox size="md" id="A2" v-model="GF.a2GreenEnergy" v-on:change="saveInformation()"
												><span v-if="GF.a2GreenEnergy">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(14, 'A3 - Led lighting')" label-for="A3" :label-cols="11">
											<b-form-checkbox size="md" id="A3" v-model="GF.a3LedLighting" v-on:change="saveInformation()"
												><span v-if="GF.a3LedLighting">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<loading :active.sync="isLoading" :is-full-page="true"></loading>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF">
						<b-row>
							<b-col id="form-step-3" lg="12">
								<div class="clearfix mb-4">
									<b-button variant="dark" size="sm" class="pull-right rounded-pill" v-b-modal.modal-gfhelp-3>
										<i class="icon-info"></i> {{ this.FormMSG(7, 'Help') }}</b-button
									>

									<h2 class="text-primary h4">{{ this.FormMSG(19, 'Transport and accomodations') }}</h2>

									<b-modal header-class="header-class-modal-doc-package" id="modal-gfhelp-3" size="lg" hide-header hide-footer>
										<h3 class="mb-3">{{ this.FormMSG(119, 'B. Transport and accomodations') }}</h3>
										<div v-html="this.FormMSG(1190, 'Transport and accomodations Help  text')"></div>
										<div v-html="this.FormMSG(1191, 'Transport and accomodations Help B1  text')"></div>
										<div v-html="this.FormMSG(1192, 'Transport and accomodations Help B2 text')"></div>
										<div v-html="this.FormMSG(1193, 'Transport and accomodations Help B3 text')"></div>
										<div v-html="this.FormMSG(1194, 'Transport and accomodations Help B4 text')"></div>
										<div class="clearfix mt-3 pt-3 border-top">
											<b-button class="pull-right" variant="secondary" @click="$bvModal.hide('modal-gfhelp-3')">{{
												this.FormMSG(10, 'Thank you !')
											}}</b-button>
										</div>
									</b-modal>
								</div>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(22, 'B1 - Vehicules EURO 5')" label-for="B1" :label-cols="11">
											<b-form-checkbox size="md" id="B1" v-model="GF.b1VehiculeEuro5" v-on:change="saveInformation()"
												><span v-if="GF.b1VehiculeEuro5">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(23, 'B2 - Vehicules Green')" label-for="B2" :label-cols="11">
											<b-form-checkbox size="md" id="B2" v-model="GF.b2VehiculeGreen" v-on:change="saveInformation()"
												><span v-if="GF.b2VehiculeGreen">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(24, 'B3 - Accomodation close by')" label-for="B3" :label-cols="11">
											<b-form-checkbox size="md" id="B3" v-model="GF.b3AccomodationCloseBy" v-on:change="saveInformation()"
												><span v-if="GF.b3AccomodationCloseBy">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(25, 'B4 - Green label accomodation')" label-for="B4" :label-cols="11">
											<b-form-checkbox size="md" id="B4" v-model="GF.b4GreenLabelAccomodation" v-on:change="saveInformation()"
												><span v-if="GF.b4GreenLabelAccomodation">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<loading :active.sync="isLoading" :is-full-page="true"></loading>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF">
						<b-row>
							<b-col id="form-step-4" lg="12">
								<div class="clearfix mb-4">
									<b-button variant="dark" size="sm" class="pull-right rounded-pill" v-b-modal.modal-gfhelp-4>
										<i class="icon-info"></i> {{ this.FormMSG(7, 'Help') }}</b-button
									>

									<h2 class="text-primary h4">{{ this.FormMSG(26, 'Catering') }}</h2>

									<b-modal header-class="header-class-modal-doc-package" id="modal-gfhelp-4" size="lg" hide-header hide-footer>
										<h3 class="mb-3">{{ this.FormMSG(127, 'C. Catering') }}</h3>
										<div v-html="this.FormMSG(1270, 'Catering Help text')"></div>
										<div v-html="this.FormMSG(1271, 'Catering Help text C1  text')"></div>
										<div v-html="this.FormMSG(1272, 'Catering Help text C2 text')"></div>
										<div v-html="this.FormMSG(1273, 'Catering Help text C3 text')"></div>
										<div v-html="this.FormMSG(1274, 'Catering Help text C4 text')"></div>
										<div class="clearfix mt-3 pt-3 border-top">
											<b-button class="pull-right" variant="secondary" @click="$bvModal.hide('modal-gfhelp-4')">{{
												this.FormMSG(10, 'Thank you !')
											}}</b-button>
										</div>
									</b-modal>
								</div>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<div>
											<strong>{{ this.FormMSG(29, 'Water') }}</strong>
										</div>
										<b-form-group class="m-0" :label="FormMSG(30, 'C1 - Tab water')" label-for="C1-1" :label-cols="11">
											<b-form-checkbox size="md" id="C1-1" v-model="GF.c1TabWater" v-on:change="saveInformation()"
												><span v-if="GF.c1TabWater">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
										<b-form-group class="m-0" :label="FormMSG(31, 'C1 - Water fontain')" label-for="C1-2" :label-cols="11">
											<b-form-checkbox size="md" id="C1-2" v-model="GF.c1WaterFontain" v-on:change="saveInformation()"
												><span v-if="GF.c1WaterFontain">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<div>
											<strong>{{ this.FormMSG(32, 'Dishes') }}</strong>
										</div>
										<b-form-group class="m-0" :label="FormMSG(33, 'C2 - Catering')" label-for="C2-1" :label-cols="11">
											<b-form-checkbox size="md" id="C2-1" v-model="GF.c2Catering" v-on:change="saveInformation()"
												><span v-if="GF.c2Catering">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
										<b-form-group class="m-0" :label="FormMSG(34, 'C2 - Restaurant')" label-for="C2-2" :label-cols="11">
											<b-form-checkbox size="md" id="C2-2" v-model="GF.c2Restaurant" v-on:change="saveInformation()"
												><span v-if="GF.c2Restaurant">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(35, 'C3 - Reusable dishes')" label-for="C3" :label-cols="11">
											<b-form-checkbox size="md" id="C3" v-model="GF.c3ReusableDishes" v-on:change="saveInformation()"
												><span v-if="GF.c3ReusableDishes">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<div>
											<strong>{{ this.FormMSG(36, 'Cup') }}</strong>
										</div>
										<b-form-group class="m-0" :label="FormMSG(37, 'C4 - Washable cup')" label-for="C4-1" :label-cols="11">
											<b-form-checkbox size="md" id="C4-1" v-model="GF.c4WashableCup" v-on:change="saveInformation()"
												><span v-if="GF.c4WashableCup">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
										<b-form-group class="m-0" :label="FormMSG(38, 'C4 - Recyclable cup')" label-for="C4-2" :label-cols="11">
											<b-form-checkbox size="md" id="C4-2" v-model="GF.c4RecyclableCup" v-on:change="saveInformation()"
												><span v-if="GF.c4RecyclableCup">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<loading :active.sync="isLoading" :is-full-page="true"></loading>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF">
						<b-row>
							<b-col id="form-step-5" lg="12">
								<div class="clearfix mb-4">
									<b-button variant="dark" size="sm" class="pull-right rounded-pill" v-b-modal.modal-gfhelp-5>
										<i class="icon-info"></i> {{ this.FormMSG(7, 'Help') }}</b-button
									>

									<h2 class="text-primary h4">{{ this.FormMSG(39, 'Material') }}</h2>

									<b-modal header-class="header-class-modal-doc-package" id="modal-gfhelp-5" size="lg" hide-header hide-footer>
										<h3 class="mb-3">{{ this.FormMSG(139, 'D. Material') }}</h3>
										<div v-html="this.FormMSG(1390, 'Material Help  text')"></div>
										<div v-html="this.FormMSG(1391, 'Material Help text D1  text')"></div>
										<div v-html="this.FormMSG(1392, 'Material Help text D2 text')"></div>
										<div v-html="this.FormMSG(1393, 'Material Help text D3 text')"></div>
										<div v-html="this.FormMSG(1394, 'Material Help text D4 text')"></div>
										<div class="clearfix mt-3 pt-3 border-top">
											<b-button class="pull-right" variant="secondary" @click="$bvModal.hide('modal-gfhelp-5')">{{
												this.FormMSG(10, 'Thank you !')
											}}</b-button>
										</div>
									</b-modal>
								</div>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<div>
											<strong>{{ this.FormMSG(42, 'Material supplier') }}</strong>
										</div>
										<b-form-group class="m-0" :label="FormMSG(43, 'D1 - Material supplier fsc')" label-for="D1-1" :label-cols="11">
											<b-form-checkbox size="md" id="D1-1" v-model="GF.d1MaterialSupplierFsc" v-on:change="saveInformation()"
												><span v-if="GF.d1MaterialSupplierFsc">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
										<b-form-group class="m-0" :label="FormMSG(44, 'D1 - Materiel supplier emas')" label-for="D1-2" :label-cols="11">
											<b-form-checkbox size="md" id="D1-2" v-model="GF.d1MaterialSupplierEmas" v-on:change="saveInformation()"
												><span v-if="GF.d1MaterialSupplierEmas">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(45, 'D2 - Material recycled')" label-for="D2" :label-cols="11">
											<b-form-checkbox size="md" id="D2" v-model="GF.d2MaterialRecycled" v-on:change="saveInformation()"
												><span v-if="GF.d2MaterialRecycled">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(46, 'D3 - Reusable stage material')" label-for="D3" :label-cols="11">
											<b-form-checkbox size="md" id="D3" v-model="GF.d3ReusableStageMaterial" v-on:change="saveInformation()"
												><span v-if="GF.d3ReusableStageMaterial">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(47, 'D4 - Electronic info exchange')" label-for="D4" :label-cols="11">
											<b-form-checkbox size="md" id="D4" v-model="GF.d4ElectronicInfoExchange" v-on:change="saveInformation()"
												><span v-if="GF.d4ElectronicInfoExchange">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<loading :active.sync="isLoading" :is-full-page="true"></loading>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF">
						<b-row>
							<b-col id="form-step-6" lg="12">
								<div class="clearfix mb-4">
									<b-button variant="dark" size="sm" class="pull-right rounded-pill" v-b-modal.modal-gfhelp-6>
										<i class="icon-info"></i> {{ this.FormMSG(7, 'Help') }}</b-button
									>

									<h2 class="text-primary h4">{{ this.FormMSG(48, 'Waste') }}</h2>

									<b-modal header-class="header-class-modal-doc-package" id="modal-gfhelp-6" size="lg" hide-header hide-footer>
										<h3 class="mb-3">{{ this.FormMSG(148, 'E. Waste') }}</h3>
										<div v-html="this.FormMSG(1480, 'Waste Help text')"></div>
										<div class="clearfix mt-3 pt-3 border-top">
											<b-button class="pull-right" variant="secondary" @click="$bvModal.hide('modal-gfhelp-6')">{{
												this.FormMSG(10, 'Thank you !')
											}}</b-button>
										</div>
									</b-modal>
								</div>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(51, 'E1 - Sort waste')" label-for="E1" :label-cols="11">
											<b-form-checkbox size="md" id="E1" v-model="GF.e1SortWaste" v-on:change="saveInformation()"
												><span v-if="GF.e1SortWaste">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<loading :active.sync="isLoading" :is-full-page="true"></loading>
							</b-col>
						</b-row>
					</b-card>

					<b-card v-if="GF">
						<b-row>
							<b-col id="form-step-7" lg="12">
								<div class="clearfix mb-4">
									<b-button variant="dark" size="sm" class="pull-right rounded-pill" v-b-modal.modal-gfhelp-7>
										<i class="icon-info"></i> {{ this.FormMSG(7, 'Help') }}</b-button
									>

									<h2 class="text-primary h4">{{ this.FormMSG(52, 'Communication') }}</h2>

									<b-modal header-class="header-class-modal-doc-package" id="modal-gfhelp-7" size="lg" hide-header hide-footer>
										<h3 class="mb-3">{{ this.FormMSG(152, 'F. Communication') }}</h3>
										<div v-html="this.FormMSG(1520, 'Communication Help text')"></div>
										<div class="clearfix mt-3 pt-3 border-top">
											<b-button class="pull-right" variant="secondary" @click="$bvModal.hide('modal-gfhelp-7')">{{
												this.FormMSG(10, 'Thank you !')
											}}</b-button>
										</div>
									</b-modal>
								</div>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(55, 'F1 - Communication press file')" label-for="F1" :label-cols="11">
											<b-form-checkbox size="md" id="F1" v-model="GF.f1ComPressFile" v-on:change="saveInformation()"
												><span v-if="GF.f1ComPressFile">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(56, 'F2 - Communication clip')" label-for="F2" :label-cols="11">
											<b-form-checkbox size="md" id="F2" v-model="GF.f2ComClip" v-on:change="saveInformation()"
												><span v-if="GF.f2ComClip">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="border-top">
									<b-col cols="12" class="p-3">
										<b-form-group class="m-0" :label="FormMSG(57, 'F3 - Communication from production')" label-for="F3" :label-cols="11">
											<b-form-checkbox size="md" id="F3" v-model="GF.f3ComFromProduction" v-on:change="saveInformation()"
												><span v-if="GF.f3ComFromProduction">{{ this.FormMSG(60, 'Yes') }}</span
												><span v-else>{{ this.FormMSG(61, 'No') }}</span></b-form-checkbox
											>
										</b-form-group>
										<b-form-group
											class="m-0"
											:label="FormMSG(58, 'Description')"
											label-for="F3-description"
											:label-cols="12"
											v-if="GF.f3ComFromProduction"
										>
											<b-form-textarea id="F3-description" :placeholder="FormMSG(59, 'Enter description...')" rows="3"></b-form-textarea>
										</b-form-group>
									</b-col>
								</b-row>

								<loading :active.sync="isLoading" :is-full-page="true"></loading>
							</b-col>
						</b-row>
					</b-card>
				</b-col>

				<b-col cols="12" md="4" class="order-2 d-none d-lg-block">
					<div class="sidenav-sticky sticky-top pb-4">
						<div class="card" v-if="GF">
							<div class="card-body">
								<nav>
									<ul class="nav flex-column">
										<li class="nav-item">
											<a class="nav-link" v-scroll-to="{ el: '#form-step-1', offset: -88 }" href="#">{{
												this.FormMSG(3, 'Mandatory Prerequisite')
											}}</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" v-scroll-to="{ el: '#form-step-2', offset: -88 }" href="#">{{
												this.FormMSG(11, 'Energy savings')
											}}</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" v-scroll-to="{ el: '#form-step-3', offset: -88 }" href="#">{{
												this.FormMSG(19, 'Transport and accomodations')
											}}</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" v-scroll-to="{ el: '#form-step-4', offset: -88 }" href="#">{{
												this.FormMSG(26, 'Catering')
											}}</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" v-scroll-to="{ el: '#form-step-5', offset: -88 }" href="#">{{
												this.FormMSG(39, 'Material')
											}}</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" v-scroll-to="{ el: '#form-step-6', offset: -88 }" href="#">{{ this.FormMSG(48, 'Waste') }}</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" v-scroll-to="{ el: '#form-step-7', offset: -88 }" href="#">{{
												this.FormMSG(52, 'Communication')
											}}</a>
										</li>
									</ul>
								</nav>
							</div>

							<div class="card-footer" v-bind:class="{ success: this.totalPts >= 20 }">
								<div>
									<span class="label">{{ this.FormMSG(202, 'Prerequisite Total Points') }}</span>
									<span class="score">{{ this.totalPts }}</span>
								</div>
							</div>
						</div>
						<div class="card-body no-padding">
							<div class="row">
								<b-col lg="12">
									<b-button variant="primary" block size="lg" v-on:click="downloadExport"
										>{{ this.FormMSG(201, 'Download Green Film document') }}
									</b-button>
								</b-col>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import Vue from 'vue';

import axios from 'axios';
import Loading from 'vue-loading-overlay';
import VueScrollTo from 'vue-scrollto';
import GreenFilmDocs from '@/components/greenfilm/greenfilmdocs';
import languageMessages from '@/mixins/languageMessages';
import { getGreenFilm,updGreenFilm } from '@/cruds/greenfilm.crud'

Vue.use(VueScrollTo);

export default {
	name: 'GreenFilmPrerequisite',
	mixins: [languageMessages],

	apollo: {
		GF: function () {
			this.reloadData();
		}
	},

	components: {
		Loading,
		GreenFilmDocs
	},

	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			GF: {},
			warning: '',
			isLoading: false,
			totalPts: 0
		};
	},

	computed: {},

	methods: {
		closeModal1() {
			//  console.log("inclosemodal1");
			this.$refs['modal1'].hide();
			this.reloadData();
			//  $bvModal.hide('modal-gfdocs-2');
		},
		closeModal2() {
			//    console.log("inclosemodal2");
			this.$refs['modal2'].hide();
			this.reloadData();
			//  $bvModal.hide('modal-gfdocs-2');
		},
		computeTotalPoints() {
			this.totalPts = 0;
			if (this.GF.a1ElectricityFromLocalNetwork) {
				this.totalPts += this.GF.a1Value;
			}
			if (this.GF.a2GreenEnergy) {
				this.totalPts += this.GF.a2Value;
			}
			if (this.GF.a3LedLighting) {
				this.totalPts += this.GF.a3Value;
			}
			if (this.GF.b1VehiculeEuro5) {
				this.totalPts += this.GF.b1Value;
			}
			if (this.GF.b2VehiculeGreen) {
				this.totalPts += this.GF.b2Value;
			}
			if (this.GF.b3AccomodationCloseBy) {
				this.totalPts += this.GF.b3Value;
			}
			if (this.GF.b4GreenLabelAccomodation) {
				this.totalPts += this.GF.b4Value;
			}
			if (this.GF.c1WaterFontain && this.GF.c1TabWater) {
				this.totalPts += this.GF.c1TabWaterValue;
			} else if (this.GF.c1WaterFontain) {
				this.totalPts += this.GF.c1WaterFontainValue;
			} else if (this.GF.c1TabWater) {
				this.totalPts += this.GF.c1TabWaterValue;
			}
			if (this.GF.c2Catering && this.GF.c2Restaurant) {
				this.totalPts += this.GF.c2CateringValue;
			} else if (this.GF.c2Catering) {
				this.totalPts += this.GF.c2CateringValue;
			} else if (this.GF.c2Restaurant) {
				this.totalPts += this.GF.c2RestaurantValue;
			}
			if (this.GF.c3ReusableDishes) {
				this.totalPts += this.GF.c3Value;
			}

			if (this.GF.c4WashableCup && this.GF.c4RecyclableCup) {
				this.totalPts += this.GF.c4WashableCupValue;
			} else if (this.GF.c4WashableCup) {
				this.totalPts += this.GF.c4WashableCupValue;
			} else if (this.GF.c4RecyclableCup) {
				this.totalPts += this.GF.c4RecyclableCupValue;
			}

			if (this.GF.d1MaterialSupplierFsc || this.GF.d1MaterialSupplierEmas) {
				this.totalPts += this.GF.d1MaterialSupplierFscValue;
			}
			if (this.GF.d2MaterialRecycled) {
				this.totalPts += this.GF.d2MaterialRecycledValue;
			}
			if (this.GF.d3ReusableStageMaterial) {
				this.totalPts += this.GF.d3Value;
			}
			if (this.GF.d4ElectronicInfoExchange) {
				this.totalPts += this.GF.d4Value;
			}
			if (this.GF.e1SortWaste) {
				this.totalPts += this.GF.e1Value;
			}
			if (this.GF.f1ComPressFile) {
				this.totalPts += this.GF.f1Value;
			}
			if (this.GF.f2ComClip) {
				this.totalPts += this.GF.f2Value;
			}
			if (this.GF.f3ComFromProduction) {
				this.totalPts += this.GF.f3Value;
			}
			//console.log("this.totalPts:",this.totalPts);
			return this.totalPts;
		},
		async reloadData() {
			this.isLoading = true;
			let greenFilm = await getGreenFilm()
			this.GF = await greenFilm;
			this.computeTotalPoints();
			this.isLoading = false;
		},
		async saveInformation() {
			//this.isLoading = true;
			var UpdatedGreenFilm = this.GF;
			var GreenFilmId = parseInt(this.GF.id, 10);
			this.GF = await updGreenFilm(GreenFilmId,UpdatedGreenFilm)
			this.computeTotalPoints();
		},
		downloadExport() {
			console.log('indownload');

			this.isLoading = true;
			this.warning = '';

			var fromDate = this.from + 'T00:00:00Z';
			var toDate = this.to + 'T00:00:00Z';

			var config = {
				headers: {
					Authorization: 'Bearer ' + store.state.token
				}
			};
			var servicePath = 'GreenFilmPrerequisiteReport';
			//console.log("servicePath:",servicePath);
			var fullPath = process.env.VUE_APP_GQL + '/' + servicePath + '/?From=' + fromDate + '&To=' + toDate + '&Email=0';
			console.log('fullPath:', fullPath);

			var axiosTmp = require('axios').create();
			axiosTmp
				.get(fullPath, config)
				.then((response) => {
					if (response.status == 200) {
						var urlStr = process.env.VUE_APP_GQL + '/images/' + response.data;
						console.log('urlStr:', urlStr);
						this.$nextTick(function () {
							console.log('in nexttick url:', urlStr);
							//window.location.href = urlStr;
							window.open(urlStr); // in a new window
						});
					}
				})
				.catch((error) => {
					console.log({
						error
					});
					if (error.message.includes('Network')) {
						this.warning = 'Network error.';
					} else if (error.response) {
						if (error.response.status == 401) {
							this.warning = 'Not connected.';
						} else if (error.response.status == 400) {
							this.warning = 'Invalid format.';
						} else if (error.response.status == 404) {
							this.warning = this.FormMSG(22, 'No Data in the selected period.');
						}
					}
				})
				.finally(() => (this.isLoading = false));
		}
	}
};
</script>
